import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import axios from './axios'
import Button from './components/atom/Button.vue'
import VCalendar from 'v-calendar'
import GAuth from 'vue3-google-oauth2'
import HighchartsVue from 'highcharts-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(faGoogle, faMicrosoft)

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VCalendar, {})
    .use(HighchartsVue)
    .use(GAuth, {
        clientId: '322222738997-01239ibkpjfjkfho4tmuuaf7mltr9nar.apps.googleusercontent.com',
        scope: 'email profile openid',
        prompt: 'select_account',
        fetch_basic_profile: false,
    })

app.mount('#app')
app.component("kumon-button", Button)
app.component("font-awesome-icon", FontAwesomeIcon)

app.config.globalProperties.$api = axios

app.config.globalProperties.$filters = {
    percentage(value) {
        return ((value) * 100).toFixed(0)
    }
  }
