<template>
  <div
    v-if="plainPage">
    <div class="container">
      <router-view />
    </div>
  </div>
  <div
    v-else-if="upEdu">
    <router-view />
  </div>
  <div v-else class="app">
    <div class="container">
      <router-view />
    </div>
    <loading :value="isLoading" />
    <div v-if="message">
      <alert
        :message="message"
        @close="clearMessage()">
        <p class="mb-6 text-weight-600">
          {{ message }}
        </p>
      </alert>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loading from './components/atom/Loading'
import Alert from './components/atom/Alert'

export default {
  name: 'App',
  components: {
    Loading, Alert,
  },
  computed: {
    ...mapState(['isLoading', 'message']),
    plainPage() {
      return this.$route.meta.plainPage
    },
    upEdu() {
      return this.$route.meta.upEdu
    },
  },
  methods: {
    clearMessage() {
      this.$store.commit('clearMessage')
    },
  },
}
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.app {
  background: white;
  min-height: 100vh;
  overflow-x: scroll;
}
.container {
  // max-width: 1366px;
  margin: auto;
  font-size: 12px;
  min-width: 900px;
  padding: 0;
}
input {
  outline: none;
}
.page-title {
  font-size: 40px;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  background: linear-gradient(to left, #28529D, #0E1D3A);
}
.hide {
  display: none;
}
h1 {
  margin-top: 0;
  padding-top: 0.67em;
}
.mr-3 {
  margin-right: 30px;
}
.mt-1 {
  margin-top: 10px;
}
.mb-1 {
  margin-bottom: 10px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.mr-1 {
  margin-right: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mt-4 {
  margin-top: 40px;
}
.pr-1 {
  padding-right: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-4 {
  padding-top: 40px;
}
.px-4 {
  padding-left: 40px;
  padding-right: 40px;
}
.mb-4 {
  margin-bottom: 40px;
}
.flex {
  display: flex;
}
.center {
  justify-content: center;
}
.align-left {
  text-align: left;
}
.rect-menu {
  background: white;
  min-width: 220px;
  border-radius: 80px;
  height: 150px;
  align-items: center;
  margin: 20px 30px;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  font-size: 29px;
  border: 1px #707070 solid;
  padding: 0px 30px;
}
.btn-bg {
  background: linear-gradient(to left, #28529D, #0E1D3A);
  color: white;
  font-weight: bold;
  padding: 10px 45px;
  border-radius: 29px;
  cursor: pointer;
  display: inline-block;
  font-size: 29px;
  border: none;
  &:disabled {
    opacity: 0.5;
  }
}
.btns-in-row {
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
  width: 60%;
  margin: auto;
}
.btn-img {
  display: inline-block;
  background: none;
  border: none;
  cursor: pointer;
  img {
    height: 55px;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.btn-img:hover {
  background: none;
  border: none;
}
// .btn-bg:active {
//   box-shadow: 6px 6px 6px 1px rgb(80 80 80 / 20%);
// }
select {
  border: none;
  font-size: 20px;
  border-radius: 5px;
  padding: 5px 10px;
}
.header {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
}
.col-1 {
  flex: 1;
}
.col-3 {
  flex: 3;
}
.font-24 {
  font-size: 24px;
}
.vc-bordered {
  border: none !important;
}
</style>
