
import { createRouter, createWebHistory } from 'vue-router'
import { store } from './store'

const login = () => import('./pages/students/Login.vue')
const firstLogin = () => import('./pages/students/FirstLogin.vue')
const menu = () => import('./pages/students/Menu.vue')
const terms = () => import('./pages/students/Terms.vue')
const configs = () => import('./pages/students/Configs.vue')
const configSchool = () => import('./pages/students/ConfigSchool.vue')
const configGrade = () => import('./pages/students/ConfigGrade.vue')
const configTextbook = () => import('./pages/students/ConfigTextbook.vue')
const steps = () => import('./pages/commons/Steps.vue')
const lessons = () => import('./pages/students/Lessons.vue')
const exams = () => import('./pages/students/Exams.vue')
const exam = () => import('./pages/students/Exam.vue')
const lessonResultType = () => import('./pages/students/LessonResultType.vue')
const lessonResults = () => import('./pages/students/LessonResults.vue')
const lessonTestResult = () => import('./pages/students/LessonTestResult.vue')
const trialTestResult = () => import('./pages/students/TrialTestResult.vue')
const studentReport = () => import('./pages/students/Report.vue')
const managerLogin = () => import('./pages/managers/Login.vue')
const managerLessonResults = () => import('./pages/managers/LessonResults.vue')
const managerTrialResults = () => import('./pages/managers/TrialResults.vue')
const managerMenu = () => import('./pages/managers/Menu.vue')
const managerSteps = () => import('./pages/commons/Steps.vue')
const managerTimeLimit = () => import('./pages/managers/TimeLimitConfig.vue')
const managerTestResults = () => import('./pages/managers/LessonResults.vue')
const reportSample = () => import('./pages/managers/ReportSample.vue')
const managerReport = () => import('./pages/managers/Report.vue')
const upEduLogin = () => import('./pages/up-edu/Login.vue')

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: login,
      meta: { requiresAuth: false }
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: { requiresAuth: false }
    },
    {
      path: '/firstLogin',
      name: 'firstLogin',
      component: firstLogin,
      meta: { requiresAuth: true }
    },
    {
      path: '/terms',
      name: 'terms',
      component: terms,
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'menu',
      component: menu,
      meta: { requiresAuth: true }
    },
    {
      path: '/config/grade',
      name: 'configGrade',
      component: configGrade,
      meta: { requiresAuth: true }
    },
    {
      path: '/config/school',
      name: 'configSchool',
      component: configSchool,
      meta: { requiresAuth: true }
    },
    {
      path: '/config/textbook',
      name: 'configTextbook',
      component: configTextbook,
      meta: { requiresAuth: true }
    },
    {
      path: '/config',
      name: 'configs',
      component: configs,
      meta: { requiresAuth: true }
    },
    {
      path: '/steps',
      name: 'steps',
      component: steps,
      meta: { requiresAuth: true }
    },
    {
      path: '/lessons',
      name: 'lessons',
      component: lessons,
      meta: { requiresAuth: true }
    },
    {
      path: '/exams',
      name: 'exams',
      component: exams,
      meta: { requiresAuth: true }
    },
    {
      path: '/lessonResultType',
      name: 'lessonResultType',
      component: lessonResultType,
      meta: { requiresAuth: true }
    },
    {
      path: '/lessonResults',
      name: 'lessonResults',
      component: lessonResults,
      meta: { requiresAuth: true }
    },
    {
      path: '/report',
      name: 'report',
      component: studentReport,
      meta: { requiresAuth: true }
    },    
    {
      path: '/result/lessonTest/:testHistoryId',
      name: 'lessonTestResult',
      component: lessonTestResult,
      meta: { requiresAuth: true, plainPage: true, }
    },
    {
      path: '/result/trialTest/:testHistoryId',
      name: 'trialTestResult',
      component: trialTestResult,
      meta: { requiresAuth: true, plainPage: true, }
    },
    {
      path: '/lessonTestResult/view',
      name: 'noAuthLessonTestResult',
      component: lessonTestResult,
      meta: { requiresAuth: false, plainPage: true, }
    },
    {
      path: '/trialTestResult/view',
      name: 'noAuthTrialTestResult',
      component: trialTestResult,
      meta: { requiresAuth: false, plainPage: true, }
    },
    {
      path: '/:examType/:lessonNo/exam',
      name: 'exam',
      component: exam,
      meta: { requiresAuth: true }
    },
    {
      path: '/manager/login',
      name: 'managerLogin',
      component: managerLogin,
      meta: { requiresAuth: false }
    },
    {
      path: '/manager/lessonResults',
      name: 'managerLessonResults',
      component: managerLessonResults,
      meta: { requiresAuth: false, requiresManager: true, }
    },
    {
      path: '/manager/trialResults',
      name: 'managerTrialResults',
      component: managerTrialResults,
      meta: { requiresAuth: false, requiresManager: true, }
    },
    {
      path: '/manager/menu',
      name: 'managerMenu',
      component: managerMenu,
      meta: { requiresAuth: false, requiresManager: true, }
    },
    {
      path: '/manager/steps',
      name: 'managerSteps',
      component: managerSteps,
      meta: { requiresAuth: false, requiresManager: true, }
    },
    {
      path: '/manager/timitLimitConfig',
      name: 'managerTimeLimit',
      component: managerTimeLimit,
      meta: { requiresAuth: false, requiresManager: true, }
    },
    {
      path: '/manager/testResults',
      name: 'managerTestResults',
      component: managerTestResults,
      meta: { requiresAuth: false, requiresManager: true, }
    },
    {
      path: '/manager/reportSample',
      name: 'reportSample',
      component: reportSample,
      meta: { requiresAuth: false, requiresManager: false, }
    },
    {
      path: '/manager/report',
      name: 'managerReport',
      component: managerReport,
      meta: { requiresAuth: false, requiresManager: false, }
    },
    {
      path: '/up-edu/login',
      name: 'upEduLogin',
      component: upEduLogin,
      meta: { requiresAuth: false, upEdu: true, title: 'アップ教育',}
    },
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  // ログイン判定
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Object.keys(store.state.auth).length) {
      next()
    } else {
      next({ path: '/', query: { redirect: to.fullPath }})
    }
  }
  // マネジャー権限判定
  if (to.matched.some(record => record.meta.requiresManager)) {
    if (store.state.auth?.isManager) {
      next()
    } else {
      next({ path: '/manager/login', query: { redirect: to.fullPath }})
    }
  }
  next()
})

export default router
