<template>
  <button
    class="btn">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    onClick(v) {
      console.log('button clicked')
      this.$emit('clicked', v)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
  border: none;
  background: white;
  border-radius: 15px;
  padding: 5px 20px;
}
</style>