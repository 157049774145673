import CreatePersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex'
import axios from './axios'

export const store = createStore({
  plugins: [CreatePersistedState({
    key: 'kummon-gauth',
    paths: ['userInfo', 'auth', 'configs', 'selectedTest',],
    storage: window.sessionStorage,
  })],
  state() {
    return {
      userInfo: {},
      auth: {},
      isLogin: false,
      isLoading: false,
      message: null,
      configs: {
        school: null,
        grade: null,
        textbook: null,
      },
      selectedTest: null,
    }
  },
  getters: {
    isLogin: state => Object.keys(state.userInfo).length > 0,
  },
  mutations: {
    login(state) {
      state.isLogin = true
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setAuthInfo(state, authResponse) {
      console.log('setAuthInfo')
      state.auth.idToken = authResponse.idToken
      state.auth.refreshToken = authResponse.refreshToken
      state.auth.toeflAppId = authResponse.toeflAppId
      state.auth.hasLicense = authResponse.hasLicense
      state.auth.isManager = authResponse.isManager
      state.auth.userId = authResponse.userId
    },
    clearAuthInfo(state) {
      state.auth = {}
      state.userInfo = {}
    },
    startLoading(state) {
      state.isLoading = true
    },
    finishLoading(state) {
      state.isLoading = false
    },
    clearMessage(state) {
      state.message = null
    },
    setMessage(state, message) {
      state.message = message
    },
    setConfigSchool(state, school) {
      state.configs.school = school
    },
    setConfigGrade(state, grade) {
      state.configs.grade = grade
    },
    setConfigTextbook(state, textbook) {
      state.configs.textbook = textbook
    },
    setConfigs(state, configs) {
      state.configs = configs
    },
    async saveConfig(state) {
      await axios.post("/configs", {
        school: state.configs.school,
        grade: state.configs.grade.year,
        class: state.configs.grade.class,
        number: state.configs.grade.number,
        textbook: state.configs.textbook,
      }).then(res => {
        console.log('config saved:' + res)
      })
    },
    selectTest(state, test) {
      state.selectedTest = test
    },
  },
})
