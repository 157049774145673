<template>
  <div class="vld-parent">
    <loading
      v-model:active="active"
      is-full-page />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

export default {
  name: 'NowLoading',
  components: {
    Loading,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      }
    }
  }
}
</script>

<style>
/* FIXME vue-loading-overlay/dist/vue-loading.css を読み込めなかったのでコピー */
.vld-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}

.vld-overlay.is-active {
  display: flex;
}

.vld-overlay.is-full-page {
  z-index: 9999;
  position: fixed;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}
</style>
