import axios from 'axios'
import router from './router'
import { store } from './store'

const errorHandle = (config, status, m) => {
  switch (status) {
    case 401:
      store.commit('setMessage', 'セッションの有効期限切れのため、再度ログインし直してください')
      router.push("/")
      break
    default:
      if (!config.hideErrorDialog) store.commit('setMessage', m || '予期せぬエラーが発生しました')
    }
}

const instance = axios.create({
  timeout: 100000,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  }
})

instance.interceptors.request.use(
  config => {
    store.commit('startLoading')
    const token = store.state.auth.idToken
    token && (config.headers.Authorization = `Bearer ${token}`)
    return config
  },
  error => Promise.error(error)
)

let retry = false

instance.interceptors.response.use(
    res => {
      store.commit('finishLoading')
      return res.status < 300 ? Promise.resolve(res) : Promise.reject(res)
    },
    error => {
      store.commit('finishLoading')
      const { response } = error
      if (response?.status == 401 && !retry) {
        retry = true
        return instance.post(`/auth/refresh?refreshToken=${store.state.auth.refreshToken}`)
          .then(res => {
            store.commit('setAuthInfo', res.data)
            return instance.request(error.response.config)
          }).finally(retry = false)
      } else {
        if (response) {
          errorHandle(response.config, response.status, response.data.message)
        } else {
          store.commit('setMessage', '予期せぬエラーが発生しました')
        }
        return Promise.reject(response)
      }
    }
)

export default instance